import { useEffect, useState } from 'react';

import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

import { ComputerDesktopIcon, DevicePhoneMobileIcon, QrCodeIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';

import {
  AppleIcon,
  ChromeColoredIcon,
  ChromeIcon,
  DiscordIcon,
  GooglePlayIcon,
  LinuxIcon,
  WindowsIcon,
} from '@/components/icons/ElementIcons';
import WalletCard from '@/components/shared/kp/WalletCard';
import BgEllipse from '@/components/shared/lp/BgEllipse';

import KomodoWalletDownloads from '@/data/KomodoWalletDownloads.json';
import { siteData } from '@/data/site';
import android from '@/public/images/lp/komodo-wallet/android.svg';
import ios from '@/public/images/lp/komodo-wallet/ios.svg';
import linux from '@/public/images/lp/komodo-wallet/linux.svg';
import macos from '@/public/images/lp/komodo-wallet/macos.svg';
import windows from '@/public/images/lp/komodo-wallet/windows.svg';
import androidqr from '@/public/images/lp/mobile/android-qr.png';
import iosqr from '@/public/images/lp/mobile/ios-qr.png';
import gradientLinkArrow from '@/public/images/lp/svgs/gradient-link-arrow.svg';

export default function Wallets() {
  const [downloadTypeTab, setDownloadTypeTab] = useState(0);
  const currentDownloadTab = downloadTypeTabs[downloadTypeTab];
  const [selectedFilter, setSelectedFilter] = useState('All');

  const filteredWalletList = (filter) => {
    if (filter === 'All') return walletListData;
    return walletListData.filter((wallet) => wallet.platforms.includes(filter));
  };

  return (
    <>
      <main className='container relative z-[100] mx-auto font-sans text-white md:grid md:grid-cols-24 md:gap-0'>
        <div className='lp-ellipse-223 absolute right-[0px] top-[-300px] z-[-10] h-[585px] w-[560px]'></div>
        <div className='lp-ellipse-224 absolute left-[-100px] top-[163px] z-[-10] h-[515px] w-[492px]'></div>
        <div className='lp-ellipse-232 absolute right-[-200px] top-[800px] z-[-10] h-[733px] w-[700px]'></div>
        <div className='lp-ellipse-233 absolute left-[-400px] top-[1700px] z-[-10] h-[783px] w-[749px]'></div>
        <div className='lp-ellipse-234 absolute right-[-300px] top-[2700px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='lp-ellipse-239 absolute bottom-[-200px] left-[-300px] z-[-10] h-[910px] w-[875px]'></div>
        <div className='hidden xl:col-span-2 xl:block'></div>
        <div className='relative w-full max-w-7xl px-4 xs:px-8 md:col-span-24 md:mx-auto xl:col-span-20 xl:px-0'>
          <section className='mx-auto flex flex-col items-center px-5 pt-[80px] sm:pt-[100px] lg:pt-[120px] 2xl:pt-[150px]'>
            <h1 className='trustless-gradient mb-[60px] mt-1 flex items-center text-center font-heading text-[73px] font-semibold leading-[120%] tracking-[-2.19px] hover:text-gray-300 sm:mb-[50px] lg:block 2xl:mb-[100px] -sm:text-[40px]'>
              Komodo Wallet
            </h1>
            <div className='w-full rounded-2xl border border-[#303030] lg:w-[900px]'>
              <div className='flex w-full items-center justify-between border-b border-b-[#303030] p-7 px-0'>
                {downloadTypeTabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`flex w-full items-center justify-center gap-1 text-center transition-opacity xms:gap-2 md:gap-3 md:text-[20px] ${
                      downloadTypeTab === index ? 'opacity-100' : 'opacity-50'
                    }`}
                    onClick={() => setDownloadTypeTab(index)}
                  >
                    <tab.labelIcon
                      className={`w-5 invert md:w-8 ${tab.doNotInvertColors && 'invert-0'}`}
                    />
                    {tab.label}
                    {tab.isBeta && (
                      <span className='rounded-full bg-[#092633] px-3 text-[12px]'>Beta</span>
                    )}
                  </button>
                ))}
              </div>
              <div className='flex flex-col p-10 lg:h-[250px]'>
                <div className='relative flex flex-[1] flex-col justify-center gap-5 md:top-[-30px]'>
                  {currentDownloadTab.contentTitle && (
                    <p className='text-center'>{currentDownloadTab.contentTitle}</p>
                  )}
                  <div className='flex flex-wrap items-center justify-center gap-5 md:flex-nowrap'>
                    {downloadTypeTabs[downloadTypeTab].actionButtons.map((button) => {
                      return button;
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='section py-md-12 py-9 pt-[80px] lg:pt-[120px] 2xl:pt-[200px]'>
            <BgEllipse className='-left-32 -z-10' />
            <div className='mx-auto'>
              <div className='fold-head pb-[2rem] text-center'>
                <h2 className='mb-[1rem] text-[2.25rem]'>Browse All Wallets</h2>
                <p className='mt-[1rem]'>List of available wallets that support KMD.</p>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='app-lists alignl filter-menu'>
                    <nav className='flex flex-wrap items-center justify-center gap-5'>
                      {['All', 'Desktop', 'Mobile', 'Hardware', 'Paper Wallet', 'Web'].map(
                        (item) => (
                          <button
                            key={item}
                            onClick={() => setSelectedFilter(item)}
                            className={`${
                              selectedFilter !== item
                                ? 'border-[#303030] bg-[#061621]'
                                : 'border border-transparent bg-gradient-to-r from-sky-500/20 via-sky-100/10 to-sky-100/20'
                            } cursor-pointer rounded-full border p-2 px-4`}
                            data-bs-toggle='pill'
                            data-filter='*'
                            data-bs-target='#appfilter'
                          >
                            <span>{item}</span>
                          </button>
                        ),
                      )}
                    </nav>
                  </div>
                  <div className='mx-auto mt-10 max-w-[1200px]'>
                    <div
                      className='grid grid-cols-1 gap-6 px-5 pt-4 sm:grid-cols-2 lg:grid-cols-3'
                      id='appfilter'
                      data-isotope='{"layoutMode": "fitRows"}'
                    >
                      {filteredWalletList(selectedFilter).map((item, index) => (
                        <WalletCard
                          key={index}
                          altText={item.altText}
                          link={item.link}
                          width={item.width}
                          height={item.height}
                          image={item.image}
                          affiliation={item.affiliation}
                          name={item.name}
                          platforms={item.platforms}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className='hidden xl:col-span-2 xl:block'></div>
      </main>
    </>
  );
}

const downloadTypeTabs = [
  {
    label: 'Web',
    labelIcon: ChromeIcon,
    doNotInvertColors: true,
    actionButtons: [
      <div className='flex flex-col items-center justify-center gap-2 lg:flex-row' key='web'>
        <motion.a href={siteData.webDEXlink} className='' target='_blank'>
          <motion.button
            type='button'
            className={`hero-btn justify-left relative flex w-[160px] flex-row items-center overflow-hidden rounded-[14px] border border-none px-3 py-2 font-sans text-base font-bold text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            whileHover={{
              y: -4,
              boxShadow: '0 4px 15px rgba(84, 104, 230, 0.6)',
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.span>Launch Wallet</motion.span>
            <ExportedImage
              src={gradientLinkArrow}
              className='h-[19px] w-auto pl-[7px]'
              width={16}
              height={17}
              alt='link'
            />
          </motion.button>
        </motion.a>
        <Link
          key='chrome_ext'
          href='https://chromewebstore.google.com/detail/komodo-wallet/dgiehkgfknklegdhekgeabnhgfjhbajd'
          target='_blank'
          className='flex flex-wrap items-center justify-center gap-2 rounded-[20px] bg-white px-[15px] py-[12px] font-[500] text-[#13141a] xms:flex-nowrap md:px-[30px]'
        >
          <ChromeColoredIcon className='h-6 w-6' />
          Install on Chrome
        </Link>
      </div>,
    ],
  },
  {
    label: 'Desktop',
    labelIcon: ComputerDesktopIcon,
    doNotInvertColors: true,
    // contentTitle: 'Supported on MacOs11+ and Windows 10+',
    actionButtons: [
      <Link href={KomodoWalletDownloads.windows} key='windows' className='all-desktop-hero-win'>
        <ExportedImage src={windows} alt='windows' className='h-auto w-[120px] md:w-[147px]' />
      </Link>,
      <Link href={KomodoWalletDownloads.macos} key='macos' className='all-desktop-hero-mac'>
        <ExportedImage src={macos} alt='mac-os' className='h-auto w-[120px] md:w-[147px]' />
      </Link>,
      <Link href={KomodoWalletDownloads.linux} key='linux' className='all-desktop-hero-linux'>
        <ExportedImage src={linux} alt='linux' className='h-auto w-[120px] md:w-[147px]' />
      </Link>,
    ],
  },
  {
    label: 'Mobile',
    labelIcon: DevicePhoneMobileIcon,
    doNotInvertColors: true,
    //isBeta: true,
    actionButtons: [
      <div key='ios' className='group relative'>
        <ExportedImage
          src={iosqr}
          alt='ios qr'
          className='absolute hidden h-auto w-[176.4px] -translate-x-[5%] -translate-y-[120%] lg:group-hover:block'
        />
        <Link href={KomodoWalletDownloads.ios} className='all-mobile-hero-ios'>
          <ExportedImage src={ios} alt='ios' className='h-auto w-[120px] md:w-[147px]' />
        </Link>
      </div>,
      <div key='android' className='group relative'>
        <ExportedImage
          src={androidqr}
          alt='android qr'
          className='absolute hidden h-auto w-[176.4px] -translate-x-[5%] -translate-y-[120%] lg:group-hover:block'
        />
        <Link href={KomodoWalletDownloads.android} className='all-mobile-hero-android'>
          <ExportedImage src={android} alt='android' className='h-auto w-[120px] md:w-[147px]' />
        </Link>
      </div>,
    ],
  },
];

const walletListData = [
  {
    altText: 'Komodo Wallet',
    link: siteData.downloadsPage,
    width: '260',
    height: '70',
    image: '/images/kp/wallets/komodo-wallet-white.png',
    affiliation: 'Official',
    name: 'Komodo Wallet',
    platforms: ['Mobile', 'Desktop', 'Web'],
  },
  {
    altText: 'Ocean QT Wallet',
    link: 'https://github.com/DeckerSU/KomodoOcean/releases',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/ocean-qt-light.png',
    affiliation: 'Official',
    name: 'Ocean QT Wallet',
    platforms: ['Desktop'],
  },
  {
    altText: 'Komodo CLI',
    link: 'https://github.com/KomodoPlatform/komodo/releases',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/komodo-cli-light.png',
    affiliation: 'Official',
    name: 'Komodo CLI',
    platforms: ['Desktop'],
  },
  {
    altText: 'Komodo Paper Wallet',
    link: 'https://deckersu.github.io/coinbin/#home',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/paper-wallet-light.png',
    affiliation: 'Official',
    name: 'Komodo Paper Wallet',
    platforms: ['Paper Wallet'],
  },
  {
    altText: 'Verus Wallet',
    link: 'https://verus.io/wallet',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/verus-wallet.png',
    affiliation: '3rd Party',
    name: 'Verus Wallet',
    platforms: ['Mobile', 'Desktop'],
  },
  {
    altText: 'Ledger',
    link: 'https://www.ledger.com/',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/ledger-light.png',
    affiliation: '3rd Party',
    name: 'Ledger',
    platforms: ['Hardware'],
  },
  {
    altText: 'Trezor',
    link: 'https://trezor.io/',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/trezor-light.png',
    affiliation: '3rd Party',
    name: 'Trezor',
    platforms: ['Hardware'],
  },
  {
    altText: 'Coinomi',
    link: 'https://coinomi.com/',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/coinomi-light.png',
    affiliation: '3rd Party',
    name: 'Coinomi',
    platforms: ['Mobile', 'Desktop'],
  },
  {
    altText: 'ZelCore',
    link: 'https://zel.network/',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/zelCore-light.png',
    affiliation: '3rd Party',
    name: 'ZelCore',
    platforms: ['Mobile', 'Desktop'],
  },
  {
    altText: 'Kriptomat',
    link: 'https://kriptomat.io/',
    width: '260',
    height: '70',
    image: '/images/kp/wallets/kriptomat.png',
    affiliation: '3rd Party',
    name: 'Kriptomat',
    platforms: ['Mobile', 'Web'],
  },
];
