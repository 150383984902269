import ExportedImage from 'next-image-export-optimizer';
import Link from 'next/link';

export default function WalletCard(item) {
  const { altText, link, width, height, image, affiliation, name, platforms } = item;
  return (
    <>
      <div className='z-10 border border-[#303030] bg-[#061621]'>
        <Link href={link} target='_blank' rel='noopener noreferrer' className='wallet-item'>
          <div className='py-20'>
            <ExportedImage
              className={`img-fluid mx-auto`}
              src={image}
              alt={altText}
              width={width}
              height={height}
            />
          </div>
          <div className='flex flex-col items-center border-t border-t-[#303030] p-5 py-7 lg:flex-row lg:justify-between'>
            <h5 className='wallet-title flex w-full flex-col -lg:flex-row -lg:items-center -lg:justify-between'>
              <small>{affiliation}</small>
              <p>{name}</p>
            </h5>
            <div className='mt-2 flex w-full flex-row items-center justify-end gap-2 lg:mt-0'>
              {platforms.map((platform, index) => (
                <span
                  key={index}
                  className={`rounded-full border border-[#5f61727d] bg-[#5a68e67d] p-1 px-3 text-[12px] ${platform
                    .toLowerCase()
                    .replace(' ', '-')}`}
                >
                  {platform}
                </span>
              ))}
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}
